'use client';
import Image from 'next/image';
import React, { useCallback, useState } from 'react';
import mobileMenuOpenIcon from '@/assets/menu-mobile-open-icon.svg';

import closeIcon from '@/assets/close-icon.svg';
import { useIsScreen } from '@/app/_hooks/useIsScreen';
import Modal from '@/components/modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import NavigationMenu from '@/components/layouts/defaultLayout/headerMenu/navigationMenu';
import ReactPopover from '@/components/layouts/defaultLayout/headerMenu/reactPopover';

const logoFull = `${DAVINCI_CDN}/elty/elty-logo.svg`;

const HeaderMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopover = useCallback(() => setIsOpen(open => !open), [setIsOpen]);

  const isMobile = useIsScreen('md');

  return (
    <>
      <ReactPopover isVisible={isOpen && !isMobile} setIsVisible={setIsOpen}>
        <div className="rounded-xl border border-neutral-100 bg-white pb-4 shadow-md">
          <NavigationMenu isMobile={isMobile} />
        </div>
      </ReactPopover>
      <button
        onClick={togglePopover}
        className={`relative h-8 w-8 rounded-lg p-2 ${isOpen ? 'bg-neutral-active' : ''}`}
        aria-controls="navbar-menu"
        aria-expanded={isOpen}
      >
        <Image className="h-full w-full" src={isOpen ? closeIcon : mobileMenuOpenIcon} alt="Menu icon" />
      </button>

      <Modal
        isModalOpen={isOpen && isMobile}
        onClose={() => setIsOpen(false)}
        className="m-0 h-full max-h-full w-full max-w-full pb-14 pt-5"
      >
        <div className="relative mb-4 flex h-7 w-full items-center justify-between px-4">
          <Image src={logoFull} alt={'Elty'} height={56} width={722} className="left-0 h-full max-h-full w-auto" />
          <XMarkIcon className="h-7 w-7 cursor-pointer md:mr-5" aria-label="Chiudi" onClick={() => setIsOpen(false)} />
        </div>
        <NavigationMenu isMobile={isMobile} />
      </Modal>
    </>
  );
};

export default HeaderMenu;
