import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const ReactPopover = ({
  children,
  isVisible,
  setIsVisible,
}: {
  children: React.ReactNode;
  isVisible: boolean;
  setIsVisible: (p: boolean) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isVisible, setIsVisible, wrapperRef]);

  return (
    <div ref={wrapperRef} className="relative">
      {isVisible && createPortal(<div className="absolute left-0 top-0 h-screen w-screen bg-black/5" />, document.body)}
      <div
        hidden={!isVisible}
        className="absolute -right-11 top-[calc(100%+20px)] z-[200] w-[310px] min-w-fit overflow-y-auto transition-all"
      >
        {children}
      </div>
    </div>
  );
};

export default ReactPopover;
