import { MobileHeaderNavSection } from '@/components/layouts/defaultLayout/headerMenu/mobileHeaderNavSection';
import { NavItem } from '@/components/layouts/defaultLayout/headerMenu/navItem';

const NavigationMenu: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  return (
    <>
      <MobileHeaderNavSection isVisible title="Visite ed esami" compact={!isMobile}>
        <NavItem url="/prenota-visita" title="Prenota visita" iconUrl="https://a.storyblok.com/f/268930/20x20/8dc4cd3148/visita.svg" />
      </MobileHeaderNavSection>
      <MobileHeaderNavSection isVisible title="Abbonamenti" compact={!isMobile}>
        <NavItem url="/abbonamenti" title="Medico online" iconUrl="https://a.storyblok.com/f/268930/20x20/9c6fa1bc37/abbonamenti.svg" />
        <NavItem
          url="/abbonamenti/pediatra-online"
          title="Pediatra online"
          iconUrl="https://a.storyblok.com/f/268930/21x20/44f5361921/pediatra-01.svg"
        />
      </MobileHeaderNavSection>
      <MobileHeaderNavSection isVisible title="Per le aziende" compact={!isMobile}>
        <NavItem
          url="/welfare-aziendale"
          title="Welfare aziendale"
          iconUrl="https://a.storyblok.com/f/268930/20x19/b26619612f/azienda.svg"
        />
        <NavItem url="/partnership" title="Partnership" iconUrl="https://a.storyblok.com/f/268930/21x21/ed957c0d81/partners.svg" />
      </MobileHeaderNavSection>
      <MobileHeaderNavSection isVisible title="Per il medico" compact={!isMobile}>
        <NavItem
          url="https://medico.davinci.elty.it/"
          title="Medici di medicina generale"
          iconUrl="https://a.storyblok.com/f/268930/21x21/fa2188b748/doct.svg"
        />
      </MobileHeaderNavSection>
    </>
  );
};

export default NavigationMenu;
